import React, { useEffect, useState } from "react";
import CharacterList from "./Components/CharacterList/index";
import "./App.css";

const App = () => {
  const [characterList, SetCharactersList] = useState([]);
  const [nextUrl, SetNextUrl] = useState(
    "https://rickandmortyapi.com/api/character/"
  );

  const getCharacters = () => {
    if (nextUrl) {
      fetch(nextUrl)
        .then((res) => res.json())
        .then((body) => {
          SetCharactersList([...characterList, ...body.results]);
          SetNextUrl(body.info.next);
        });
    }
  };
  useEffect(getCharacters, [nextUrl]);

  return (
    <div className="App">
      <header className="App-header">
        <CharacterList list={characterList} />
      </header>
    </div>
  );
};

export default App;
